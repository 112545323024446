import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppStatus } from '@/typings/app-auth';
let AppDetail = class AppDetail extends Vue {
    constructor() {
        super(...arguments);
        this.AppStatus = AppStatus;
    }
};
__decorate([
    Prop({ default: () => ({}) })
], AppDetail.prototype, "appDetail", void 0);
AppDetail = __decorate([
    Component({
        name: 'AppDetail',
    })
], AppDetail);
export default AppDetail;
